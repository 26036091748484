import { computed, ref, SetupContext } from '@vue/composition-api';

import { getWorklet } from '@/services/worklets.service';
import { Worklet, WorkletsResponse } from '@/models/worklet';
import { DataTableState } from '@/utils/data-table-state';
import { TableHeader } from '@/types/table-options';
import { QueryStateStorageConfig } from '@/utils/storage';
import { showStandardHttpErrorNotification } from '@/utils/util';
import { EditorLanguages } from '@/components/component.types';
import {
  WORKLETS_IS_LOADING,
  WORKLETS_REQUEST,
} from '@/store/actions/worklets';
import {
  DEFAULT_TABLE_PAGINATION,
  TABLE_ITEMS_PER_PAGE,
} from '@/utils/constants';

export const workletsHeaders: TableHeader[] = [
  {
    text: 'Worklet Name',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'name',
  },
  {
    text: 'Verified',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'is_verified',
    align: 'center',
  },
  {
    text: 'OS',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'os_family',
    align: 'center',
  },
  {
    text: 'Category',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'category',
  },
  {
    text: 'Creator',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'creator',
  },
  {
    text: 'Last Updated',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'update_time',
  },
  {
    text: 'Actions',
    align: 'center',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: false,
    value: 'actions',
  },
];

export const workletsFooter = {
  'items-per-page-options': TABLE_ITEMS_PER_PAGE,
  'items-per-page-text': 'Worklets per page:',
};

// All Worklets
export function useWorklets(context: SetupContext) {
  const orgId = computed(() => Number(context.root.$route.query!.o));
  const loading = computed<boolean>(
    () => context.root.$store.getters[WORKLETS_IS_LOADING],
  );
  const worklets = ref<WorkletsResponse>({} as WorkletsResponse);

  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-system-worklets-prefs',
    store: localStorage,
  };

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize(
      context.root.$route,
      context.root.$router,
      {
        o: { defaultValue: orgId.value },
        limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
        sort: {
          defaultValue: 'name:desc',
        },
        page: { defaultValue: 1, excludeFromBrowserUrl: true },
        columns: {
          defaultValue: [],
          excludeFromApiUrl: true,
          storeInBrowser: true,
        },
        internal: {
          defaultValue: 1,
          excludeFromBrowserUrl: true,
        },
        q: { defaultValue: [], apiKeyTranslation: 'name:like' },
      },
      {
        storageConfig,
        callback: ({ apiQuery }) => {
          getWorkletCatalog(apiQuery);
        },
      },
    );

  function getWorkletCatalog(args) {
    context.root.$store
      .dispatch(WORKLETS_REQUEST, args)
      .then((response) => {
        worklets.value = response;
      })
      .catch(showStandardHttpErrorNotification);
  }

  return {
    loading,
    worklets,
    tableState,
    queryState,
    updateQueryState,
    getWorkletCatalog,
  };
}

// Single Worklet
export function useWorklet() {
  const worklet = ref<Worklet>({});

  function getSingleWorklet(wId: string | number): Promise<Worklet> {
    return getWorklet(wId);
  }

  return { worklet, getSingleWorklet };
}

export function useWorkletCodeFormatter(worklet: Worklet) {
  const editorLanguage = computed(() =>
    worklet.os_family === 'Windows'
      ? EditorLanguages.PowerShell
      : EditorLanguages.Shell,
  );
  return { editorLanguage };
}
