import { computed, Ref } from '@vue/composition-api';
import * as N from '@ax/function-utils/nullable';
import * as R from '@ax/vue-utils/ref';
import { prop } from '@ax/object-utils';
import { constZero, pipe } from '@ax/function-utils';
import {
  GetPolicyResults,
  runGetPolicyResultsQuery,
  useGetPolicyResultsQuery,
} from '@/services/policy-results.service';

/**
 * Composition used to simplify getting the policy results state by using the other helper
 * functions in this file.
 *
 * @param zoneId The zone UUId.
 * @param policyUuid The policy UUID.
 * @returns The reactive state for policy results.
 */
export function usePolicyResults(
  zoneId: Ref<string | undefined>,
  policyUuid: Ref<string | undefined>,
) {
  const query = computed(() => {
    const currentZoneId = zoneId.value;
    const currentPolicyUuid = policyUuid.value;
    return N.isNonNullable(currentZoneId) && N.isNonNullable(currentPolicyUuid)
      ? new GetPolicyResults({
          zoneId: currentZoneId,
          policyUuid: currentPolicyUuid,
        })
      : undefined;
  });
  const { data: policyResults, ...remaining } = useGetPolicyResultsQuery(query);

  const lastUpdated = R.map_(policyResults, N.map(prop('timestamp')));

  const totalResults = computed(() =>
    pipe(
      policyResults.value,
      N.map(prop('total_results')),
      N.getOrElse(constZero),
    ),
  );

  function refresh() {
    if (query.value) {
      return runGetPolicyResultsQuery(query.value);
    }
  }

  return {
    ...remaining,
    refresh,
    policyResults,
    lastUpdated,
    totalResults,
  };
}
