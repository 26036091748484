import { computed, Ref } from '@vue/composition-api';
import { VuetifyDataTableConfiguration } from '@/utils/data-table-state';

export function useSkipCountTableConfigs(
  isSkipCountOrgPrefOn: Ref<boolean>,
  defaultFooter: object,
  rows: Ref<readonly unknown[]>,
  tableState: Ref<VuetifyDataTableConfiguration>,
  defaultTotalRows: Ref<number | undefined>,
) {
  const footerProps = computed(() => {
    if (isSkipCountOrgPrefOn.value) {
      const isLastPage = rows.value.length < tableState.value.itemsPerPage;
      const itemsSoFar =
        tableState.value.itemsPerPage * (tableState.value.page - 1);
      const start = itemsSoFar + 1;
      const stop = itemsSoFar + rows.value.length;
      return {
        ...defaultFooter,
        'page-text': isLastPage
          ? `${start}-${stop} of ${stop}`
          : `${start}-${stop}`,
        pagination: {
          itemsPerPage: tableState.value.itemsPerPage,
          page: tableState.value.page,
          pageStart: start,
          pageStop: stop,
          itemsLength: isLastPage ? rows.value.length : Number.MAX_SAFE_INTEGER,
          pageCount: isLastPage
            ? tableState.value.page
            : tableState.value.page + 1,
        },
      };
    }
    return defaultFooter;
  });

  const serverItemsLength = computed(() => {
    return isSkipCountOrgPrefOn.value
      ? Number.MAX_SAFE_INTEGER
      : defaultTotalRows.value;
  });

  return { footerProps, serverItemsLength };
}
