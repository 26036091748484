import { computed, Ref } from '@vue/composition-api';

import { OSFamily } from '@ax/data-services-devices/models/oses';
import { useOrgOSesQuery } from '@/services/oses.service';
import { OrgId } from '@/models/org-id';

export function useOses(orgId: Ref<OrgId>) {
  const { data: oses } = useOrgOSesQuery(orgId);

  const totalOses = computed<number>(() => {
    const osesValue = oses.value;
    return osesValue
      ? Object.values(osesValue).reduce(
          (acc: number, next: OSFamily) =>
            acc + Object.values(next.oses!).length,
          0,
        )
      : 0;
  });

  return {
    oses,
    totalOses,
  };
}
