import Vue from 'vue';
import { computed, ref, watch, SetupContext, Ref } from '@vue/composition-api';
import { format, addYears } from 'date-fns';
import { CancelTokenSource, AxiosResponse } from 'axios';
import { useModule } from 'vuex-simple';
import { useCurrentOrgId } from '@ax/vue-utils/router';
import { DataTableState } from '@ax/data-table-state';
import { DataTableHeader } from 'vuetify';
import { useStore } from '@/compositions/useStore';

import { StateModules } from '@/store';
import { Policy, PolicySchedule } from '@/models/policy';
import {
  getPolicySchedule,
  runPolicy,
  updatePolicy,
  createPolicy,
  deletePolicy,
  getPolicy,
  createPolicyFile,
  deletePolicyFile,
  getPolicies as requestPolicies,
  getPolicyFiles as fetchPolicyFiles,
} from '@/services/policies.service';
import { FilterOption } from '@/types/select-item';
import { DATE_FORMAT } from '@/utils/date-time';
import { AxFile } from '@/models/file';
import {
  DEFAULT_TABLE_PAGINATION,
  TABLE_ITEMS_PER_PAGE,
} from '@/utils/constants';
import {
  getPolicyTypeName,
  getPolicyScheduleText,
  getScheduleTimeText,
  getAutoPatchUsage,
  getServerGroupCount,
  getDeviceFiltersValue,
} from '@/utils/policies';
import { QueryStateStorageConfig } from '@/utils/storage';
import { showStandardHttpErrorNotification } from '@/utils/util';
import { capitalize } from '@/utils/display-text';

import {
  FileUploadComponentPublicAPI,
  EditorLanguages,
} from '@/components/component.types';
import { SystemManagementStateModule } from '@/store/system-management.module';

export function usePolicyForm(
  context: SetupContext,
  heightElementRef: Ref<HTMLElement | null>,
  policy: Ref<Policy>,
) {
  const { frompage, o, type, policy_type } = context.root.$route.query;

  const queryParams = {
    o,
    frompage,
    type: type && capitalize(String(type)), // OS Type
    policy_type,
  };

  const mdAndUp = computed<boolean>(
    () => context.root.$vuetify.breakpoint.mdAndUp,
  );
  const groupHeight = ref<string | number | undefined>();

  const submitting = ref(false);

  function goBack() {
    // These unused vars cause the properties to be omitted from the "rest" query object
    // Has a bunch of history behind this, so disabling rule instead of fixing.
    // See PRs: #371, #360, and #176
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      pid: policyId,
      frompage: previousPage,
      ...query
    } = context.root.$route.query;
    /* eslint-enable */

    let path: string = (frompage as string) || '/manage/policies';

    // Path should = 'manage' or the user will be redirected again to the Create Policy page.
    if (path.includes('worklet-catalog')) {
      path = '/manage/policies';
    }

    context.root.$router.push({ path, query });
  }

  function submit(policyForm: Policy): Promise<boolean> {
    const payload = removeEmptyConfigValues(policyForm, true);

    submitting.value = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const savePolicyPromise: Promise<any> = policyForm.id
      ? updatePolicy(payload)
      : createPolicy(payload);
    return savePolicyPromise
      .then(() => {
        submitting.value = false;
        Vue.notify({
          group: 'snackbar',
          text: `Success: The policy has been ${
            policy.value.id ? 'updated' : 'created'
          }.`,
          type: '{ "card": "success" }',
        });
        goBack();
        return true;
      })
      .catch((error) => {
        submitting.value = false;
        showStandardHttpErrorNotification(error);
        return false;
      });
  }

  watch(
    [mdAndUp, heightElementRef],
    ([isMdAndUp, infoSection]) => {
      // TODO: Vue 3 TypeScript improvement
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const element: HTMLDivElement = infoSection && (infoSection as any).$el;
      if (isMdAndUp && element) {
        groupHeight.value = element.offsetHeight;
      } else {
        groupHeight.value = 'auto';
      }
    },
    {
      immediate: true,
    },
  );

  return {
    groupHeight,
    goBack,
    submit,
    submitting,
    queryParams,
  };
}

export function usePolicyFiles(
  templateRef: Ref<FileUploadComponentPublicAPI | undefined>,
) {
  const policyFiles = ref<AxFile[]>([]);
  const loadingFileList = ref(false);
  const fileCancellationToken = ref<CancelTokenSource | null>(null);

  function getPolicyFiles(policy: Policy): Promise<AxFile[]> {
    if (policy.id) {
      loadingFileList.value = true;
      return fetchPolicyFiles(policy)
        .then((response) => {
          policyFiles.value = response;
          return response;
        })
        .finally(() => {
          loadingFileList.value = false;
        });
    }
    return Promise.resolve([]);
  }

  function uploadPolicyFile(
    p: Policy,
    file: File,
  ): Promise<[Policy, AxFile] | void> {
    const policy = removeEmptyConfigValues(p);

    loadingFileList.value = true;
    const progressFn = templateRef.value!.handleProgress;
    const createNewPolicyPromise = policy.id
      ? Promise.resolve(policy)
      : createPolicy(policy);
    return createNewPolicyPromise
      .then((newPolicy) => {
        // if the input policy does not have an id.  newPolicy will always have an id
        if (!policy.id) {
          Vue.notify({
            group: 'snackbar',
            text: 'The policy was created.',
            type: '{ "card": "success" }',
          });
        }
        return newPolicy;
      })
      .then((operablePolicy) => {
        if (operablePolicy) {
          const [token, promise] = createPolicyFile(
            operablePolicy,
            file,
            progressFn,
          );
          fileCancellationToken.value = token;
          return promise
            .then((savedFileResponse) => {
              getPolicyFiles(operablePolicy);
              return savedFileResponse.data;
            })
            .catch((error) => {
              loadingFileList.value = false;
              // undefined errors are thrown when users cancel upload requests
              if (error) {
                showStandardHttpErrorNotification(error);
              }
            })
            .then(
              (savedFileData) =>
                [operablePolicy, savedFileData] as [Policy, AxFile],
            );
        }
        loadingFileList.value = false;
        return Promise.reject();
      })
      .catch((error) => {
        loadingFileList.value = false;
        showStandardHttpErrorNotification(error);
      });
  }

  function removePolicyFile(
    policy: Policy,
    fileData: { index: number; file: AxFile },
  ): Promise<AxiosResponse | void> {
    return deletePolicyFile(policy, fileData)
      .then((response) => {
        policyFiles.value.splice(fileData.index, 1);
        return response;
      })
      .catch(showStandardHttpErrorNotification);
  }

  function cancelFileUpload(): void {
    fileCancellationToken.value!.cancel();
    templateRef.value!.cancelUpload();
  }

  return {
    getPolicyFiles,
    uploadPolicyFile,
    cancelFileUpload,
    removePolicyFile,
    policyFiles,
    loadingFileList,
  };
}

export function usePolicyCodeEditor(policy: Policy) {
  const editorLanguage = computed(() =>
    policy.configuration?.os_family === 'Windows'
      ? EditorLanguages.PowerShell
      : EditorLanguages.Shell,
  );
  return { editorLanguage };
}

export function usePolicies() {
  const systemManagementState = useModule<SystemManagementStateModule>(
    useStore(),
    [StateModules.systemManagement],
  )!;

  const orgId = useCurrentOrgId();
  const policies = computed(() => systemManagementState.policiesListData);
  const policiesSelect = computed(() =>
    systemManagementState.policiesListData.map((policy) => ({
      id: policy.id,
      name: policy.name || 'Default',
      type: getPolicyTypeName(policy),
    })),
  );
  const policiesSearchOptions = computed(() =>
    mapSearchOption(systemManagementState.policiesListData),
  );

  watch(
    orgId,
    (next, prev) => {
      if (!prev || next !== prev) {
        getPolicies(next);
      }
    },
    {
      immediate: true,
    },
  );

  function getPolicies(org: number) {
    systemManagementState.dispatchPoliciesListRequest(() =>
      requestPolicies(org),
    );
  }

  // TODO Remove when refactoring
  function mapSearchOption(nextPolicies: Policy[]): FilterOption[] {
    return nextPolicies.map((policy) => ({
      text: `Policy - ${policy.name || 'Default'}`,
      value: { key: 'policies', value: policy.id || -1 },
    }));
  }

  function getSinglePolicy(
    policyId: string | number,
    org: number,
  ): Promise<Policy> {
    return getPolicy(policyId, org);
  }

  return {
    policies,
    policiesSelect,
    policiesSearchOptions,
    getPolicies,
    getSinglePolicy,
  };
}

export const defaultPoliciesTableHeaders: DataTableHeader[] = [
  {
    text: 'Name',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'name',
  },

  {
    text: 'Type',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'policy_type',
  },
  {
    text: 'Has Device Targeting',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    filterable: false,
    value: 'device_filters_enabled',
  },
  {
    text: 'Schedule',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    filterable: false,
    sortable: true,
    value: 'schedule',
  },
  {
    text: 'Schedule Time',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    filterable: false,
    sortable: true,
    value: 'schedule_time_display',
  },
  {
    text: 'Devices',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    filterable: false,
    sortable: true,
    value: 'server_count',
  },
  {
    text: 'Groups',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    value: 'server_group_count',
  },
  {
    text: 'Status',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    filterable: false,
    sortable: true,
    value: 'auto_patch_in_use',
  },
  {
    text: 'Actions',
    align: 'center',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    filterable: false,
    sortable: false,
    value: 'actions',
  },
];

export const fullPoliciesTableHeaders: DataTableHeader[] = [
  ...defaultPoliciesTableHeaders,
  /**
   * Update as of 04/18/2020
   *
   * https://automox.atlassian.net/browse/APEX-383
   * This field is calculated incorrectly and should not be shown until it is
   */
  // {
  //   text: 'Next Patch Window',
  //   class: 'tw-whitespace-no-wrap',
  //   divider: true,
  //   sortable: true,
  //   filterable: false,
  //   value: 'next_remediation',
  // },
  {
    text: 'OS',
    class: 'tw-whitespace-no-wrap',
    divider: true,
    sortable: true,
    filterable: true,
    value: 'configuration.os_family',
  },
];

export function getDisplayableTableDataFromPolicies(
  policies: Policy[],
): Policy[] {
  return policies.map((policy) => ({
    ...policy,
    policy_type: getPolicyTypeName(policy),
    schedule: getPolicyScheduleText(policy),
    schedule_time_display: getScheduleTimeText(policy),
    server_group_count: getServerGroupCount(policy.server_groups),
    auto_patch_in_use: getAutoPatchUsage(policy),
    device_filters_enabled: getDeviceFiltersValue(policy),
  }));
}

export function usePoliciesTabTableState(context: SetupContext) {
  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-system-policies-prefs',
    store: localStorage,
  };

  const { tableState, queryState, updateQueryState, updateColumnsState } =
    DataTableState.synchronize<Policy, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
      context.root.$route,
      context.root.$router,
      {
        o: { defaultValue: Number(context.root.$route.query.o) },
        page: { defaultValue: 1 },
        limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
        sort_by: { defaultValue: 'name' },
        sort_dir: { defaultValue: 'asc' },
        search: { defaultValue: undefined },
        columns: {
          defaultValue: [
            'name',
            'policy_type',
            'device_filters_enabled',
            'schedule',
            'schedule_time_display',
            'server_count',
            'server_group_count',
            'auto_patch_in_use',
            'actions',
          ],
          excludeFromApiUrl: true,
          storeInBrowser: true,
        },
      },
      { storageConfig },
    );

  watch(
    () => context.root.$route.query.o,
    (next, prev) => {
      if (prev && prev !== next) {
        updateQueryState({ o: next, search: undefined });
      }
    },
    {
      immediate: true,
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleSearch(value: any, search: string | null, item: any): boolean {
    const normalizedSearch = search ? search.toLowerCase() : '';

    return (
      item.name.toLowerCase().indexOf(normalizedSearch) !== -1 ||
      item.policy_type.toLowerCase().indexOf(normalizedSearch) !== -1
    );
  }

  return {
    tableState,
    queryState,
    updateQueryState,
    updateColumnsState,
    handleSearch,
  };
}

export function runPolicyAction(
  orgId: string | number,
  policyId: string | number,
  deviceId?: string | number,
  action = 'remediateServer',
) {
  return runPolicy(orgId, policyId, deviceId, action);
}

export function getSchedule(
  orgId: string | number,
  schedule: Partial<Policy>,
): Promise<PolicySchedule[]> {
  const startDate = format(new Date(), DATE_FORMAT);
  const endDate = format(addYears(new Date(), 1), DATE_FORMAT);
  return getPolicySchedule(orgId, startDate, endDate, schedule);
}

export function hasValidSchedule(policy: Policy): boolean {
  return (
    Boolean(policy.schedule_days) &&
    Boolean(policy.schedule_weeks_of_month) &&
    Boolean(policy.schedule_months)
  );
}

export function createNewPolicy(
  policy: Policy,
): Promise<AxiosResponse | Policy> {
  return createPolicy(policy);
}

export function updateExistingPolicy(policy: Policy): Promise<AxiosResponse> {
  return updatePolicy(policy);
}

export function removeExistingPolicy(policy: Policy): Promise<AxiosResponse> {
  return deletePolicy(policy);
}

export const policiesFooter = {
  'items-per-page-options': TABLE_ITEMS_PER_PAGE,
  'items-per-page-text': 'Policies per page:',
};

export function removeEmptyConfigValues(
  p: Policy,
  removeEmptyRebootDefermentPeriods = false,
): Policy {
  return {
    ...p,
    configuration: {
      ...p.configuration,
      ...(removeEmptyRebootDefermentPeriods && {
        custom_pending_reboot_notification_deferment_periods:
          p.configuration?.custom_pending_reboot_notification_deferment_periods?.filter(
            (period) => period,
          ),
      }),
      device_filters:
        p.configuration?.device_filters &&
        p.configuration?.device_filters_enabled
          ? p.configuration.device_filters.filter(
              (f) =>
                // Has all 3 required values
                Object.keys(f).length === 3 &&
                // All values have been filled
                Object.values(f).every((v) => !!v),
            )
          : [],
    },
  };
}
