import { SetupContext, ref, computed, watch } from '@vue/composition-api';
import { AxiosResponse } from 'axios';

import { User } from '@ax/data-services-user/models/user';
import i18n from '@/i18n';
import { OrgTfa, UserTfa } from '@/models/tfa';
import {
  getUserTfa,
  getOrgTfa,
  postOrgTfa,
  putUserTfa,
} from '@/services/tfa.service';
import { AppStore } from '@/store';
import { USER_GET_PROFILE } from '@/store/actions/user';

/**
 * @deprecated Composition will not function after Auth0 Milestone 1 launches
 */
export function useTfa(context: SetupContext) {
  const orgId = ref<number>(context.root.$route.query.o as unknown as number);
  const route = computed(() => context.root.$route);

  const orgTfa = ref<OrgTfa>();

  watch(
    route,
    (next, prev) => {
      const nextOrgId = next.query.org || next.query.o;
      const prevOrgId = prev && (prev.query.org || prev.query.o);
      if (nextOrgId && nextOrgId !== prevOrgId) {
        orgId.value = Number(nextOrgId);
        reloadOrgTfa();
      }
    },
    {
      immediate: true,
    },
  );

  function reloadOrgTfa() {
    getOrgTfa(orgId.value).then((response) => {
      orgTfa.value = response;
    });
  }

  function updateOrgTfa(require_tfa: boolean): Promise<AxiosResponse> {
    if (orgId) {
      return postOrgTfa(orgId.value, require_tfa);
    }
    return Promise.reject(new Error(i18n.ts('org.provideError')));
  }

  return {
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    orgTfa,
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    updateOrgTfa,
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    reloadOrgTfa,
  };
}

/**
 * @deprecated Composition will not function after Auth0 Milestone 1 launches
 */
export function useUserTfa() {
  const userTfas = ref<UserTfa[]>([]);
  const currentUser = computed<User>(() => {
    return AppStore.getters[USER_GET_PROFILE];
  });
  const currentUserTfa = ref<UserTfa>();
  const tfaType = ref(
    currentUserTfa.value && currentUserTfa.value.tfa_type
      ? currentUserTfa.value.tfa_type
      : 'disabled',
  );

  function reloadCurrentUserTfa(): Promise<UserTfa> {
    if (currentUser.value && currentUser.value.id) {
      const promise = getUserTfa(currentUser.value.id);
      promise.then((response) => {
        currentUserTfa.value = response;
        if (
          currentUserTfa.value &&
          typeof currentUserTfa.value.tfa_type !== 'undefined'
        ) {
          tfaType.value =
            currentUserTfa.value.tfa_type === null
              ? 'disabled'
              : currentUserTfa.value.tfa_type;
        }
      });
      return promise;
    }
    return Promise.reject(new Error('Current user not set'));
  }
  reloadCurrentUserTfa();

  function getSelectedTfas(ids: number[]) {
    return ids.map((id) => getUserTfa(id));
  }

  function updateUserTfa(
    userId: number,
    tfa_type: string,
    token?: string,
  ): Promise<AxiosResponse> {
    return putUserTfa(userId, tfa_type, token);
  }

  return {
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    userTfas,
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    currentUserTfa,
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    reloadCurrentUserTfa,
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    updateUserTfa,
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    getSelectedTfas,
    /**
     * @deprecated Composition will not function after Auth0 Milestone 1 launches
     */
    tfaType,
  };
}
