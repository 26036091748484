import { computed, ref, shallowRef, watch } from '@vue/composition-api';
import { getZones } from '@ax/api-clients-accounts/clients/zones.client';
import {
  AccountRbacRoleDTO,
  AssignmentAction,
  Zone,
  ZoneAssignmentWithAction,
  ZoneRbacRole,
} from '@ax/api-clients-accounts/models';
import useRbacRoles from './useRbacRoles';

export interface ZoneRoleAssigment {
  zone: Pick<Zone, 'id'>;
  rbac_role: ZoneRbacRole;
}

function isFullZone(zone: Partial<Zone>): zone is Zone {
  const {
    id,
    account_id,
    name,
    created_by,
    access_key,
    created_at,
    updated_at,
  } = zone;
  return !!(
    id !== undefined &&
    account_id !== undefined &&
    name !== undefined &&
    created_by !== undefined &&
    access_key !== undefined &&
    created_at !== undefined &&
    updated_at !== undefined
  );
}

export default function useZonesAddEditUser(props) {
  const loading = ref<boolean>(true);
  const { roles } = useRbacRoles();

  const zones = ref<Zone[]>([]);
  const fetchZones = async () => {
    const response = await getZones('limit=500');
    zones.value = response.data;
  };

  // selected zones which may have full details or id only
  const selectedZones = shallowRef<ZoneRoleAssigment[]>(
    props.selectedZones || [],
  );
  // selected zones with full details
  const fullSelectedZones = computed(() => {
    const allZones = zones.value;
    return selectedZones.value.map(({ rbac_role, zone }) => ({
      rbac_role,
      zone: isFullZone(zone) ? zone : allZones.find(({ id }) => id === zone.id),
    }));
  });

  // update local selected zones if prop changes
  watch(
    () => props.selectedZones,
    () => {
      selectedZones.value = props.selectedZones;
    },
  );
  // selected zones formatted for the paged list with full detail attached
  const selectedZoneItems = computed(() =>
    fullSelectedZones.value
      .map((zoneData) => {
        const matchedRole = roles.value.find(
          ({ slug }) => slug === zoneData.rbac_role,
        );
        if (!matchedRole) {
          return null;
        }
        return {
          role: matchedRole,
          sub: matchedRole.name,
          text: zoneData.zone?.name,
          zone: zoneData.zone,
        };
      })
      .filter((zoneItem) => zoneItem),
  );

  // contain all changes to zones in memory for easy access when saving to the API
  const zoneChanges = ref<ZoneAssignmentWithAction[]>(props.zoneChanges || []);
  // update local zone changes if prop changes
  watch(
    () => props.zoneChanges,
    () => {
      zoneChanges.value = props.zoneChanges;
    },
  );

  const addZone = (zoneWithRole: { zone: Zone; rbac_role: string }) => {
    // don't allow adding if zone already exists (shouldn't be UI-possible)
    if (
      selectedZones.value.find(
        (zoneData) => zoneData.zone.id === zoneWithRole.zone.id,
      )
    ) {
      return;
    }

    selectedZones.value = [zoneWithRole, ...selectedZones.value];

    // track zone changes
    const alreadyDeletedZoneIndex = zoneChanges.value.findIndex(
      (zoneChange) =>
        zoneChange.zone_id === zoneWithRole.zone.id &&
        zoneChange.action === 'remove',
    );
    if (alreadyDeletedZoneIndex === -1) {
      // not already deleted, mark as added
      zoneChanges.value = zoneChanges.value.concat({
        action: AssignmentAction.add,
        name: zoneWithRole.zone.name,
        rbac_role: zoneWithRole.rbac_role,
        zone_id: zoneWithRole.zone.id,
      });
    } else if (
      zoneChanges.value[alreadyDeletedZoneIndex].rbac_role !==
      zoneWithRole.rbac_role
    ) {
      // treat it as a change action when the role has changed
      zoneChanges.value[alreadyDeletedZoneIndex] = {
        action: AssignmentAction.change,
        name: zoneWithRole.zone.name,
        rbac_role: zoneWithRole.rbac_role,
        zone_id: zoneWithRole.zone.id,
      };
    } else {
      // already deleted, simply remove the deleted change
      zoneChanges.value = zoneChanges.value
        .slice(0, alreadyDeletedZoneIndex)
        .concat(zoneChanges.value.slice(alreadyDeletedZoneIndex + 1));
    }
  };

  const deleteZone = (index) => {
    const zoneToDelete = fullSelectedZones.value[index];
    if (zoneToDelete.zone) {
      const {
        zone: { id, name },
        rbac_role,
      } = zoneToDelete;

      selectedZones.value = selectedZones.value
        .slice(0, index)
        .concat(selectedZones.value.slice(index + 1));

      // track zone changes
      const addedZoneToRemoveIndex = zoneChanges.value.findIndex(
        (zoneChange) => zoneChange.zone_id === id,
      );
      if (addedZoneToRemoveIndex === -1) {
        // was not added, delete
        zoneChanges.value = zoneChanges.value.concat({
          action: AssignmentAction.remove,
          name,
          rbac_role,
          zone_id: id,
        });
      } else {
        // was added, simply remove
        zoneChanges.value = zoneChanges.value
          .slice(0, addedZoneToRemoveIndex)
          .concat(zoneChanges.value.slice(addedZoneToRemoveIndex + 1));
      }
    }
  };

  const editZone = (zone: Zone, role: AccountRbacRoleDTO) => {
    // update selected role on the zone
    selectedZones.value = selectedZones.value.map((zoneData) =>
      zoneData.zone.id === zone.id
        ? { ...zoneData, rbac_role: role.slug }
        : zoneData,
    );

    // track zone changes
    const alreadyExistingZoneIndex = zoneChanges.value.findIndex(
      (zoneChange) => zoneChange.zone_id === zone.id,
    );
    if (alreadyExistingZoneIndex === -1) {
      // zone not yet edited/added, add change
      zoneChanges.value = zoneChanges.value.concat({
        action: AssignmentAction.change,
        name: zone.name,
        zone_id: zone.id,
        rbac_role: role.slug,
      });
    } else {
      // zone already edited/added, update change
      zoneChanges.value = zoneChanges.value.map((zoneChange, index) =>
        index === alreadyExistingZoneIndex
          ? { ...zoneChange, rbac_role: role.slug }
          : zoneChange,
      );
    }
  };

  (async () => {
    try {
      await fetchZones();
    } finally {
      loading.value = false;
    }
  })();

  return {
    addZone,
    deleteZone,
    editZone,
    loading,
    selectedZoneItems,
    selectedZones,
    zoneChanges,
    zones,
  };
}
