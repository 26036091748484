import { SegmentAnalytics } from '@ax/data-services-tracking/analytics';
import { getRoleNameInOrg } from '@ax/data-services-user/business-logic';
import { User } from '@ax/data-services-user/models/user';
import { UnknownRecord } from '@ax/object-utils';
import { useCurrentOrgId } from '@ax/vue-utils/router';
import { Ref } from '@vue/composition-api';
import { OrgId } from '@/models/org-id';

export interface UseTrackingProps {
  readonly user: Ref<User>;
  readonly orgId?: Ref<OrgId>;
}

export function useTracking(props: UseTrackingProps) {
  const { user, orgId = useCurrentOrgId() } = props;

  /**
   * Segment wrapper that manages the logic to retrieve the user org role and
   * and sends it to the service
   * @param eventName a string representing the event, e.g. "Click: Devices Link"
   * @param location a string representing current user page location
   * @param options additional options we may want to send along with the role
   */
  function track(eventName: string, location: string, options?: UnknownRecord) {
    const role = getRoleNameInOrg(user.value, orgId.value as number);
    const optionsToSend = {
      role,
      ...(options && { ...options }),
    };

    SegmentAnalytics.track(eventName, {
      location,
      ...optionsToSend,
    });
  }

  return { track };
}
