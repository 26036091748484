import { computed } from '@vue/composition-api';
import { getRbacRoles } from '@ax/api-clients-accounts/clients/rbacRoles.client';
import { RoleType } from '@ax/api-clients-accounts/models';
import { roles, setRoles } from '@/state/rbac-roles.state';

const fetchRoles = async () => {
  if (!roles.value.length) {
    const { data } = await getRbacRoles();
    setRoles(data);
  }
};

// Should be used when you only want to read/display the RBAC roles.
// Fetches the RBAC roles to make sure they will be populated without the consumer having to do anything.
// If you need to edit/modify roles and update the shared state instance,
// DO NOT USE this and instead access rbac-roles.state directly.

export default function useRbacRoles() {
  // Populate roles if it has not been set yet
  fetchRoles();

  const useRolesByType = (roleType: RoleType) =>
    computed(() => {
      return roles.value.filter(({ type }) => type === roleType);
    });

  const useRolesByWhitelist = (roleNameWhitelist: string[]) =>
    computed(() => {
      return roles.value.filter(({ slug }) => roleNameWhitelist.includes(slug));
    });

  return {
    useRolesByType,
    useRolesByWhitelist,
    roles,
  };
}
