import { consoleI18n } from '@ax/console-i18n';
import { shallowRef } from '@vue/composition-api';

export interface VulnerabilityQueryState {
  /**
   * A list of CVE's
   */
  readonly vulnerability: string[];
}

/**
 * CVE's string length must be >= 6
 */
const CVE_MIN_LENGTH = 6;

export const VulnerabilityFilterError = [
  consoleI18n.t('general.validations.minLength', {
    minLength: CVE_MIN_LENGTH,
  }),
];

/**
 * Check if a string is a valid CVE
 * @param str `CVE`
 * @returns `boolean` indicating if the string input is a valid CVE
 */
function isValidCVE(str: string) {
  return str.length >= CVE_MIN_LENGTH;
}

/**
 * Use to check if all the new CVE's valid. If yes, the update should
 * be applied.
 */
function shouldApplyUpdate(values: readonly string[]) {
  return values.every(isValidCVE);
}

/**
 * Return a handler `handleVulnerabilityFilter` that can take values of the form `string[]` and
 * potentially modifies the `queryState` if the values are all valid. Also returns a `vulnerabilityFilterError`
 * which is a `string[]` of validation errors.
 * @param updateQueryState A function that can take in `{ vulnerability: string[] }` and update the query state
 */
export function useVulnerabilityFilter(
  updateQueryState: (newVulnerabilityState: VulnerabilityQueryState) => void,
) {
  const vulnerabilityFilterError = shallowRef<readonly string[]>([]);

  /**
   * Update the `queryState` with the `values` if they are valid CVEs, otherwise
   * update the error value to reflect the invalid input.
   * @param values string[] A list of CVEs
   */
  function handleVulnerabilityFilter(values: string[]): void {
    if (shouldApplyUpdate(values)) {
      vulnerabilityFilterError.value = [];
      updateQueryState({ vulnerability: values });
    } else {
      vulnerabilityFilterError.value = VulnerabilityFilterError;
    }
  }

  return {
    vulnerabilityFilterError,
    handleVulnerabilityFilter,
  };
}
