import { AxiosResponse } from 'axios';
import {
  createUser,
  updateUserRbac,
} from '@ax/api-clients-accounts/clients/users.client';
import {
  AccountInvitation,
  AccountRbacRole,
  AccountUserCreate,
  AccountUserUpdate,
  ZoneAssignmentWithAction,
} from '@ax/api-clients-accounts/models';

export default function useZonesAddEditUser() {
  const saveExisting = async (
    userId: string,
    userData: Omit<AccountUserUpdate, 'zone_assignments'>,
    zoneChanges: ZoneAssignmentWithAction[],
  ): Promise<AxiosResponse> => {
    return updateUserRbac(userId, {
      ...userData,
      zone_assignments:
        userData.account_rbac_role === AccountRbacRole.GlobalAdmin
          ? []
          : zoneChanges,
    });
  };

  const saveNew = async (
    userData: Omit<AccountUserCreate, 'zone_assignments'>,
    zoneChanges: ZoneAssignmentWithAction[],
  ): Promise<AccountInvitation> => {
    return createUser({
      ...userData,
      zone_assignments:
        userData.account_rbac_role === AccountRbacRole.GlobalAdmin
          ? []
          : zoneChanges,
    });
  };

  return {
    saveExisting,
    saveNew,
  };
}
