import { UserOfZone } from '@/models/zones';

function moveUserToBeginning(
  userToMove: UserOfZone,
  userList: UserOfZone[],
): UserOfZone[] {
  return userList.reduce<UserOfZone[]>((acc, user) => {
    return user.email === userToMove.email
      ? [userToMove, ...acc]
      : [...acc, user];
  }, []);
}

export function addUserToList(
  usersToAdd: UserOfZone[],
  userList: UserOfZone[],
): UserOfZone[] {
  return usersToAdd.reduce((acc, user) => {
    const userInCurrentList = userList.find(
      ({ email }) => email.toLowerCase() === user.email.toLowerCase(),
    );
    return userInCurrentList ? moveUserToBeginning(user, acc) : [user, ...acc];
  }, userList);
}

export function removeUserAtIndex(
  userList: UserOfZone[],
  index: number,
): UserOfZone[] {
  const usersBeforeUserToRemove = userList.slice(0, index);
  const usersAfterUserToRemove = userList.slice(index + 1);
  return [...usersBeforeUserToRemove, ...usersAfterUserToRemove];
}

export function updateUserRole(
  userList: UserOfZone[],
  userToUpdate: UserOfZone,
): UserOfZone[] {
  return userList.map((user) => {
    if (user.email !== userToUpdate.email) {
      return user;
    }
    return { ...user, rbac_role: userToUpdate.rbac_role! };
  });
}
