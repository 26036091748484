import {
  TableHeader,
  TABLE_ITEMS_PER_PAGE,
  DEFAULT_TABLE_PAGINATION,
} from '@ax/cosmos/components/Table';
import { showStandardHttpErrorNotification } from '@ax/notifications';
import { useCurrentOrgId, useRoute, useRouter } from '@ax/vue-utils/router';
import { StorageConfig } from '@ax/web-storage-wrapper';
import {
  computed,
  reactive,
  ref,
  shallowRef,
  watch,
} from '@vue/composition-api';
import { format } from 'date-fns';
import { useModule } from 'vuex-simple';
import { DataTableState } from '@ax/data-table-state';
import { consoleI18n } from '@ax/console-i18n';
import { DATE_TIME_ZONE_FORMAT } from '@ax/date-time-utils';
import { PlanName } from '@ax/data-services-account/models/billing';
import { DataTableHeader } from 'vuetify';
import { AxiosResponseError } from '@ax/api-clients-common/models';
import { Nullable } from '@ax/type-utils';
import { useReactiveMap } from 'vue-reactive-collection';
import Vue from 'vue';
import { useStore } from '@/compositions/useStore';
import { SystemManagementStateModule } from '@/store/system-management.module';
import { StateModules } from '@/store';
import {
  getBatches,
  GetBatchesQuery,
  getBatchTasks,
  GetBatchTasksQuery,
} from '@/services/tasks.service';
import {
  TaskBatch,
  TaskDetails,
  TaskDetailsType,
  TaskDeviceStatuses,
  TaskStatuses,
} from '@/models/task';
import { postExecution } from '@/services/integration-actions.service';
import { ActionType } from '@/models/integration-actions';
import { setCurrentExecutionId } from './useExecutionBanner';
import { useOrganizationTier } from './useOrganizationTier';

const storageConfig: StorageConfig = {
  key: 'ax-remediations-prefs',
  store: localStorage,
};

const batchesHeaders: DataTableHeader[] = [
  {
    text: 'Name',
    divider: false,
    sortable: true,
    value: 'source',
  },
  {
    text: 'Queued By',
    divider: false,
    sortable: false,
    value: 'created_by_user.email',
  },
  {
    text: 'Status',
    divider: false,
    sortable: true,
    value: 'status',
  },
  {
    text: 'Type',
    divider: false,
    sortable: false,
    value: 'report_source',
  },
  {
    text: '# of Remediations',
    divider: false,
    sortable: false,
    value: 'total_tasks',
  },
  {
    text: 'Updated',
    divider: false,
    sortable: true,
    value: 'updated_at',
  },
  {
    text: 'Next Scheduled Run',
    divider: false,
    sortable: false,
    value: 'next_run',
  },
  {
    text: 'Actions',
    divider: false,
    sortable: false,
    value: 'actions',
  },
];

const batchesFooter = {
  'items-per-page-options': TABLE_ITEMS_PER_PAGE,
  'items-per-page-text': 'Batches per page:',
};

export function useAvrCheck() {
  const { currentOrgHasTierOfAtLeast } = useOrganizationTier();
  const hasTierThree = currentOrgHasTierOfAtLeast(PlanName.tier3);

  return computed(() => hasTierThree.value);
}

export function useRouteBatchId() {
  const route = useRoute();

  return computed(() => Number(route.params.batchId));
}

export function useRemediationsTable() {
  const systemManagementState = useModule<SystemManagementStateModule>(
    useStore(),
    [StateModules.systemManagement],
  )!;
  const batches = computed(() => systemManagementState.taskBatches);
  const orgId = useCurrentOrgId();
  const loadTimestamp = shallowRef<string>('');
  const serverItemsLength = shallowRef<number>();

  function fetchBatches(query: GetBatchesQuery): Promise<void> {
    return getBatches(orgId.value, query)
      .then((response) => {
        systemManagementState.setTaskBatches(response.data);
        serverItemsLength.value = response.metadata.total;
      })
      .catch(showStandardHttpErrorNotification);
  }

  async function runConfiguration(configuration_id: string) {
    try {
      const executionId = await postExecution(orgId.value, configuration_id);

      setCurrentExecutionId(
        ActionType.AutomatedVulnerabilityRemediation,
        executionId,
        orgId.value,
      );
    } catch (e) {
      showStandardHttpErrorNotification(
        e instanceof Error ? undefined : (e as AxiosResponseError),
      );
    }
  }

  function isAutomatedRemediation(
    configurationId: Nullable<string>,
  ): configurationId is string {
    return !!(
      configurationId &&
      configurationId !== '00000000-0000-0000-0000-000000000000'
    );
  }

  const { tableState, queryState, updateQueryState, updateColumnsState } =
    DataTableState.synchronize<TaskBatch, GetBatchesQuery>(
      useRoute(),
      useRouter(),
      {
        o: { defaultValue: orgId.value },
        limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
        page: { defaultValue: 1 },
        sort: { defaultValue: 'updated_at:desc' },
        'status:not_in': {
          defaultValue: ['rejected'],
          excludeFromBrowserUrl: true,
        },
        'type:equals': { defaultValue: 'patch', excludeFromBrowserUrl: true },
        columns: {
          defaultValue: [],
          storeInBrowser: true,
          excludeFromApiUrl: true,
        },
      },
      {
        callback: ({ apiQuery }: { apiQuery: GetBatchesQuery }) => {
          loadTimestamp.value = format(new Date(), DATE_TIME_ZONE_FORMAT);
          return fetchBatches(apiQuery);
        },
        storageConfig,
      },
    );

  watch(orgId, (next, prev) => {
    if (prev && next !== prev) {
      updateQueryState({ o: next, limit: DEFAULT_TABLE_PAGINATION, page: 1 });
    }
  });

  return {
    batches,
    batchesFooter,
    batchesHeaders,
    isAutomatedRemediation,
    loadTimestamp,
    orgId,
    queryState,
    runConfiguration,
    serverItemsLength,
    tableState,
    updateQueryState,
    updateColumnsState,
  };
}

/**
 * Helper function to check if a given value contains the given search term.
 *
 * @param value Value to search.
 * @param search Search term.
 */
function containsSearch(value, search: string) {
  return (
    value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !==
    -1
  );
}

/**
 * Custom search filter for tables which supports searching values which are arrays of strings.
 *
 * @param value The value being searched.
 * @param search The search query.
 */
export function tableSearchFilterSupportingArrays(
  value,
  search: string | null,
) {
  if (!value || !search) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.some((val) => val && containsSearch(val, search));
  }

  return typeof value !== 'boolean' && containsSearch(value, search);
}

const cveImpactedDevicesHeaders: TableHeader[] = [
  {
    text: consoleI18n.t('general.labels.device'),
    divider: false,
    sortable: true,
    value: 'custom_name',
  },
  {
    text: consoleI18n.t('tasks.remediations.details.devicesTable.hostname'),
    divider: false,
    sortable: true,
    value: 'name',
  },
  {
    text: consoleI18n.t('tasks.remediations.details.devicesTable.privateIP'),
    divider: false,
    sortable: true,
    value: 'ip_addrs_private',
  },
  {
    text: consoleI18n.t(
      'tasks.remediations.details.devicesTable.remediationStatus',
    ),
    divider: false,
    sortable: true,
    filterable: false,
    value: 'status',
  },
];

export function useCveImpactedDevicesTable() {
  return { headers: cveImpactedDevicesHeaders };
}

const unknownHostsHeaders: TableHeader[] = [
  {
    text: consoleI18n.t('general.labels.device'),
    divider: false,
    sortable: true,
    value: 'hostname',
  },
];

export function useUnknownHostsTable() {
  return { headers: unknownHostsHeaders };
}

const workletSelectionHeaders: TableHeader[] = [
  {
    text: 'Worklet Name',
    divider: false,
    sortable: true,
    value: 'name',
  },
  {
    text: 'OS',
    divider: false,
    sortable: true,
    value: 'configuration.os_family',
  },
  {
    text: 'Notes',
    divider: false,
    sortable: false,
    value: 'notes',
  },
  {
    text: 'Details',
    divider: false,
    sortable: false,
    value: 'details',
  },
];

export function useWorkletSelectionTable() {
  return { headers: workletSelectionHeaders };
}

export function useCveList(filter: TaskDetailsType) {
  const orgId = useCurrentOrgId();
  const batchId = useRouteBatchId();
  const loadTimestamp = shallowRef<string>('');
  const serverItemsLength = shallowRef<number>();

  // v-model doesn't like using expanded.get(...), so have to use a regular object instead of Map
  const expanded = ref<Record<number, boolean>>({});
  const tasks = useReactiveMap<number, TaskDetails>(new Map());

  function fetchTasks(query: Partial<GetBatchTasksQuery>): Promise<void> {
    return getBatchTasks(orgId.value, batchId.value, query)
      .then((response) => {
        loadTimestamp.value = format(new Date(), DATE_TIME_ZONE_FORMAT);

        const data = response.data?.tasks || [];
        tasks.value = new Map(data.map((task) => [task.id, reactive(task)]));

        // Keep old IDs in the lookup so that expansion state is maintained through page traversal
        data.forEach((task, index) => {
          if (!(task.id in expanded.value)) {
            Vue.set(
              expanded.value,
              task.id,
              index === 0 && response.metadata.current_page === 0,
            );
          }
        });

        serverItemsLength.value = response.metadata.total;
      })
      .catch(showStandardHttpErrorNotification);
  }

  const configuration = {
    o: { defaultValue: orgId.value },
    limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    page: { defaultValue: 1 },
    'type:in': {
      defaultValue: [filter],
      excludeFromBrowserUrl: true,
    },
  };

  const options = {
    callback: ({ apiQuery }: { apiQuery: GetBatchTasksQuery }) => {
      return fetchTasks(apiQuery);
    },
    storageConfig: {
      key: 'ax-remediations-detail-list-prefs',
      store: localStorage,
    },
    ignoreFirstTableUpdate: false,
  };

  const { tableState, queryState } = DataTableState.synchronize<
    TaskDetails,
    GetBatchTasksQuery
  >(useRoute(), useRouter(), configuration, options);

  function updateTaskToInProgress(id: number) {
    const task = tasks.value.get(id)!;
    task.status = TaskStatuses.In_Progress;
    task.devices.forEach((device) => {
      device.status = TaskDeviceStatuses.In_Progress;
    });
    tasks.value.set(task.id, task);
  }

  function updatePerPage(value) {
    tableState.value = { ...tableState.value, itemsPerPage: value, page: 1 };
  }

  function next() {
    tableState.value = { ...tableState.value, page: tableState.value.page + 1 };
  }

  function prev() {
    tableState.value = { ...tableState.value, page: tableState.value.page - 1 };
  }

  const paginationDetails = computed(() => {
    return {
      page: tableState.value.page,
      itemsPerPage: tableState.value.itemsPerPage,
      pageCount: serverItemsLength.value
        ? Math.ceil(serverItemsLength.value / tableState.value.itemsPerPage)
        : 1,
      itemsLength: serverItemsLength.value || 0,
      updatePerPage,
      next,
      prev,
    };
  });

  function refresh() {
    const apiQuery = DataTableState.toApiUrlQuery<
      TaskDetails,
      GetBatchTasksQuery
    >(queryState.value, configuration, options);
    return fetchTasks(apiQuery);
  }

  return {
    tasks,
    expanded,
    updateTaskToInProgress,
    paginationDetails,
    loadTimestamp,
    refresh,
  };
}
