import { computed, onUnmounted, ref, unref, watch } from '@vue/composition-api';
import { consoleI18n } from '@ax/console-i18n';
import { parseHttpErrors } from '@ax/api-clients-common';
import { AxiosResponseError } from '@ax/api-clients-common/models';
import { createGlobalState, MaybeRef, useLocalStorage } from '@vueuse/core';
import Vue from 'vue';
import { getExecution } from '@/services/integration-actions.service';
import { showStandardHttpErrorNotification } from '@/utils/util';
import {
  ActionExecution,
  ActionExecutionOutput,
  ActionExecutionStatus,
  ActionType,
} from '@/models/integration-actions';
import { OrgId } from '@/models/org-id';

const POLLING_INTERVAL_MS = 5000;
const STORE_KEY = 'integration-executions';

const useExecutionIds = createGlobalState(() =>
  useLocalStorage(STORE_KEY, { executionIds: {} }, { writeDefaults: false }),
);

export function useExecutionId(actionType: ActionType, orgId: MaybeRef<OrgId>) {
  const executionIds = useExecutionIds();

  return computed<string | undefined>({
    get() {
      const orgMap = executionIds.value.executionIds[actionType] || {};
      return orgMap[unref(orgId)?.toString()];
    },
    set(id) {
      const orgIdVal = unref(orgId);
      if (orgIdVal) {
        const orgMap = executionIds.value.executionIds[actionType] || {};
        Vue.set(orgMap, orgIdVal.toString(), id);
        Vue.set(executionIds.value.executionIds, actionType, orgMap);
      }
    },
  });
}

export function setCurrentExecutionId(
  actionType: ActionType,
  id: string | undefined,
  orgId: MaybeRef<OrgId>,
) {
  const executionId = useExecutionId(actionType, orgId);
  executionId.value = id;
}

interface StatusBannerProps {
  readonly subject: string;
  readonly status: string;
  readonly description: string;
  readonly secondary?: string;
}

function statusIsTerminal(status: ActionExecutionStatus) {
  return (
    status === ActionExecutionStatus.Completed ||
    status === ActionExecutionStatus.Error ||
    status === ActionExecutionStatus.Timeout
  );
}

function getPrettyKeyName(key: string) {
  const exploded = key.split('_');

  exploded[0] = exploded[0].charAt(0).toUpperCase() + exploded[0].slice(1);

  return exploded.join(' ');
}

function buildSecondaryDescription(output: ActionExecutionOutput | undefined) {
  if (!output) {
    return undefined;
  }

  const parts: string[] = [];

  Object.entries(output).forEach(([key, value]) => {
    if (key !== 'summary') {
      parts.push(`${getPrettyKeyName(key)}: ${JSON.stringify(value)}`);
    }
  });

  if (parts.length === 0) {
    return undefined;
  }

  return parts.join('\n');
}

function buildBannerProps(
  execution: ActionExecution,
  actionType: ActionType,
): StatusBannerProps {
  const subject = consoleI18n.t(
    `integrations.executionBanner.subjects.${actionType}`,
  );
  let status = 'unknown';
  let description;
  let secondary;

  switch (execution.status) {
    case ActionExecutionStatus.Created:
    case ActionExecutionStatus.Queued:
    case ActionExecutionStatus.Running:
      status = 'in-progress';
      description = execution.output?.summary;
      secondary = buildSecondaryDescription(execution.output);
      break;
    case ActionExecutionStatus.Completed:
      status = 'success';
      description = execution.output?.summary;
      secondary = buildSecondaryDescription(execution.output);
      break;
    case ActionExecutionStatus.Error:
      status = 'failed';
      description = execution.error_message;
      if (execution.error_code) {
        secondary = consoleI18n.t('integrations.executionBanner.errorCode', {
          error_code: execution.error_code,
        });
      }
      break;
    case ActionExecutionStatus.Timeout:
      status = 'failed';
      description = execution.error_message;
      break;
  }

  if (!description) {
    let key = `integrations.executionBanner.defaults.${execution.status}`;
    if (!consoleI18n.te(key)) {
      key = 'integrations.executionBanner.defaults.unknown';
    }
    description = consoleI18n.t(key, { status: execution.status });
  }

  return {
    description,
    subject,
    secondary,
    status,
  };
}

function buildBannerPropsForError(
  error: AxiosResponseError,
  actionType: ActionType,
): StatusBannerProps {
  return {
    subject: consoleI18n.t(
      `integrations.executionBanner.subjects.${actionType}`,
    ),
    status: 'alert',
    description: consoleI18n.t('integrations.executionBanner.pollingError'),
    secondary: parseHttpErrors(
      consoleI18n.t('general.notifications.genericError'),
      consoleI18n.t('general.notifications.errorPrefix'),
      error,
    ),
  };
}

export function useExecutionBanner(
  actionType: ActionType,
  orgId: MaybeRef<OrgId>,
) {
  const executionId = useExecutionId(actionType, orgId);

  const bannerProps = ref<StatusBannerProps | null>(null);

  let pollingInterval: number | undefined;

  function stopPolling() {
    clearInterval(pollingInterval);
    pollingInterval = undefined;
  }

  function startPolling() {
    pollingInterval = setInterval(
      () => updateBanner(false),
      POLLING_INTERVAL_MS,
    );
  }

  function updateBanner(isFirstUpdate: boolean) {
    const requestedId = executionId.value!;
    getExecution(requestedId, unref(orgId), isFirstUpdate)
      .then((execution) => {
        if (executionId.value !== requestedId) {
          return;
        }

        if (statusIsTerminal(execution.status)) {
          stopPolling();
        } else if (isFirstUpdate) {
          startPolling();
        }

        bannerProps.value = buildBannerProps(execution, actionType);
      })
      .catch((error) => {
        stopPolling();
        showStandardHttpErrorNotification(error);

        bannerProps.value = buildBannerPropsForError(error, actionType);
      });
  }

  watch(executionId, (next) => {
    stopPolling();

    if (next) {
      updateBanner(true);
    } else {
      bannerProps.value = null;
    }
  });

  if (executionId.value) {
    updateBanner(true);
  }

  onUnmounted(() => {
    stopPolling();
  });

  return { executionId, bannerProps };
}

// Only exported for test coverage, don't use these directly.
export { statusIsTerminal, buildBannerProps, buildBannerPropsForError };
